import React from 'react';
import { Typography, Tooltip, Form, Input, Button, Row, Col, Rate, Space, Radio, Checkbox, Result } from 'antd';
import { InfoCircleOutlined, SmileOutlined, StarOutlined, FrownOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Title } = Typography;
const Global = props => {
    const [form1] = Form.useForm();

    //* Отправка формы
    async function user_send(v) {
        for( let value in v) {
            if(v[value] instanceof Array) {
               v[value] = v[value].join('!=@=!');
            }
        };
        v['hash'] = props.state.hash;
        v['method'] = 'user_send';
        await props.api(v,true);
    }


    return (
        <React.Fragment>
            {
                Object.keys(props.state.info).length > 0 ? (
                    <React.Fragment>
                        {
                            props.state.info.platform ? (
                                <div className="full_end">
                                    <div className="top_end" >
                                        <div className="fcolumn full_height">
                                            <div className="logoheader_image logoheader_positive"></div>
                                            <div className="fcolumn end_center">
                                                <div className="fcenter end_title_1">
                                                    Мы благодарны за высокую оценку!
                                                </div>
                                                <div className="fcenter end_title_2">
                                                    Оставьте, пожалуйста, свой отзыв о нас на картах
                                                </div>
                                                
                                            </div>
                                            <div className="fcenter end_button">
                                                <Button className="des-button" href={props.state.info.data.url} target="_blank" shape="round" size="large">
                                                    Оставить отзыв
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bottom_end">
                                        <div className="logoheader_image_end logoheader_positive_end"></div>
                                    </div>
                                </div>
                            ) : (
                                <div className="full_end">
                                    <div className="top_end" >
                                        <div className="fcolumn full_height">
                                            <div className="logoheader_image logoheader_negative"></div>
                                            <div className="fcolumn end_center">
                                                <div className="fcenter end_title_1">
                                                    Нам очень жаль, что Вам не понравилось
                                                </div>
                                                <div className="fcenter end_title_2">
                                                    Ваши результаты опроса будут рассмотрены руководством клиники
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bottom_end">
                                        <div className="logoheader_image_end logoheader_negative_end"></div>
                                    </div>
                                </div>
                            )
                        }
                    </React.Fragment>
                ) : (
                    <Form name="form" form={form1} onFinish={user_send} className="form">
                        <div className="headerfull">
                            <div className="logoheader"></div>
                        </div>
                        <div className="mainbody">
                            <div className="title_top">
                                Опрос на качество обслуживания
                            </div>
                            <div className="body_full widthfull">
                                <div className="body_container wowload">
                                    <div key={`krate`} style={{padding:20}} >
                                        <Title style={{marginBottom:20,marginTop:10}} className="title_item" level={5}>На сколько Вам понравилось посещение клиники?</Title>
                                        <div className="fcolumn">
                                            <Form.Item className="" name={`rate`} rules={[{ required: true, message: 'Пожалуйста, укажите оценку!' }]}>
                                                <Rate allowClear={false} style={{ fontSize: 36, color:'#73CAF2' }} />
                                            </Form.Item>
                                            <div className="des-divider"></div>
                                        </div>
                                    </div>
                                    {
                                        props.state.reviews.map( (rev, index) => 
                                            <div key={`k${index}`} style={{padding:'20px 20px 0 20px'}} >
                                                <Title style={{marginBottom:20,marginTop:10}} className="title_item" level={5}>{rev.text}</Title>
                                                {rev.type === '1' && 
                                                    <div className="fcolumn">
                                                        <Form.Item className="" name={`ans-${rev.id}`}>
                                                            <TextArea showCount maxLength={200} rows={2} />
                                                        </Form.Item>
                                                        {index+1 < props.state.reviews.length &&
                                                            <div className="des-divider"></div>
                                                        }
                                                    </div>
                                                }
                                                {rev.type === '2' && 
                                                    <div className="fcolumn">
                                                        <Form.Item className="" name={`ans-${rev.id}`} rules={[{ required: true, message: 'Пожалуйста, выберите один вариант ответа!' }]}>
                                                            <Radio.Group>
                                                                <Space direction="vertical">
                                                                    {
                                                                        rev.answers.map( (ans, index) => 
                                                                            <Radio value={ans.id}>{ans.text}</Radio>
                                                                        )
                                                                    }
                                                                </Space>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                        {index+1 < props.state.reviews.length &&
                                                            <div className="des-divider"></div>
                                                        }
                                                    </div>
                                                }
                                                {rev.type === '3' && 
                                                    <div className="fcolumn">
                                                        <Form.Item className="" name={`ans-${rev.id}`} rules={[{ required: true, message: 'Пожалуйста, выберите один вариант ответа!' }]}>
                                                            <Checkbox.Group>
                                                                <Space direction="vertical">
                                                                    {
                                                                        rev.answers.map( (ans, index) => 
                                                                            <Checkbox value={ans.id}>{ans.text}</Checkbox>
                                                                        )
                                                                    }
                                                                </Space>
                                                            </Checkbox.Group>
                                                        </Form.Item>
                                                        {index+1 < props.state.reviews.length &&
                                                            <div className="des-divider"></div>
                                                        }
                                                    </div>
                                                }
                                                {rev.type === '4' && 
                                                    <div className="fcolumn">
                                                        <Form.Item className="" name={`ans-${rev.id}`} rules={[{ required: true, message: 'Пожалуйста, укажите оценку!' }]}>
                                                        <Rate allowClear={false} style={{ fontSize: 36, color:'#73CAF2' }} />
                                                        </Form.Item>
                                                        {index+1 < props.state.reviews.length &&
                                                            <div className="des-divider"></div>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <Col key="k1_submit" style={{padding:15}} className="widthfull bg_bottom" >
                                <div className="fcenter">
                                    <Form.Item>
                                        <Button className="des-button" shape="round" htmlType="submit">Отправить</Button>
                                    </Form.Item>
                                </div>
                            </Col>
                        </div>
                    </Form>
                )
            }
        </React.Fragment>
    )
};

export default Global;